import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import HandshakeIcon from "@mui/icons-material/Handshake";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import GroupsIcon from "@mui/icons-material/Groups";
import GppGoodIcon from "@mui/icons-material/GppGood";
import PsychologyIcon from "@mui/icons-material/Psychology";
import VerifiedIcon from "@mui/icons-material/Verified";
import "./styles.css";
const ValuesInfo = () => {
  return (
    <div className="container_general_aboutus">
      <Box className="container_info_nosotros" sx={{ backgroundColor: "#484848", paddingBottom: 3, height: "494px", marginTop: "5px"}}>
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",
            paddingY: 7,
            color: "#FFFFFF",
            fontWeight: "bolder",
            fontSize: "xx-large"
          }}
        >
          NUESTROS VALORES
        </Typography>
        <Grid
          sx={{ padding: 3, display: "flex", justifyContent: "center" }}
          container
          //   spacing={1}
          //   rowSpacing={1}
          //   columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12} sm={2} md={2}>
            <div className="icons container-type">
              <HandshakeIcon />
            </div>
            <div className="description-values"><br></br>
              <h6>HONESTIDAD</h6><br></br>
              <p className="texto_capsula">
              Es la cualidad que caracteriza a nuestros funcionarios. Respetando la verdad en relación con la organización, sus clientes y el entorno en general.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <div className="icons container-type">
              <LocalMallIcon />
            </div>
            <div className="description-values"><br></br>
              <h6>COMPROMISO</h6><br></br>
              <p className="texto_capsula">
              Nuestra gestión tiene como fin el cumplimiento de metas que van, más allá de lo obvio y de los previsible, bajo un profundo sentido de responsabilidad.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <div className="icons container-type">
              <GroupsIcon />
            </div>
            <div className="description-values"><br></br>
              <h6>TRABAJO COLABORATIVO</h6><br></br>
              <p className="texto_capsula">
              Es la fuerza que nos permite prestar un excelente servicio, ligados a la solidaridad y el compañerismo, logramos alcanzar las metas desde un aprendizaje continuo.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <div className="icons container-type">
              <GppGoodIcon />
            </div>
            <div className="description-values"><br></br>
              <h6>SERVICIO EXCEPCIONAL</h6><br></br>
              <p className="texto_capsula">
              La actitud de servicio superior y solución oportuna, acompañan nuestros procesos de gestión enfocados en construir confianza con los distintos grupos de interés.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <div className="icons container-type">
              <PsychologyIcon />
            </div>
            <div className="description-values"><br></br>
              <h6>INNOVACIÓN</h6><br></br>
              <p className="texto_capsula">
              Es la capacidad que a través de la experiencia y el trabajo en equipo, nos permite adoptar y desarrollar nuevos productos y servicios que lleven a la empresa a ser más competitiva.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <div className="icons container-type">
              <VerifiedIcon />
            </div>
            <div className="description-values"><br></br>
              <h6>CALIDAD</h6><br></br>
              <p className="texto_capsula">
              Nuestros procesos se encuentran enmarcados en un sistema de gestión de calidad que permiten la satisfacción de las necesidades del mercado.
              </p>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ValuesInfo;
