export const idDocument = [
    {
      value: "CC",
      label: "Cédula de Ciudadania",
    },
    {
      value: "CE",
      label: "Cédula de Extranjeria",
    },
    {
      value: "PS",
      label: "Pasaporte",
    },
    {
      value: "NIT",
      label: "Nit",
    },
  ];
  export const citiesOrDepartment = [
    {
      value: "Antioquia",
      label: "Antioquia",
    },
    {
      value: "Bogota",
      label: "Bogota DC",
    },
    {
      value: "Atlantico",
      label: "Atlantico",
    },
    {
      value: "Valle",
      label: "Valle del Cauca",
    },
  ];
  export const departments = [
    {
      value: "Seleccione",
      label: "Seleccione un departamento",
    },
    {
      value: "Antioquia",
      label: "Antioquia",
    },
    {
      value: "Bogota",
      label: "Bogota DC",
    },
    {
      value: "Atlantico",
      label: "Atlantico",
    },
    {
      value: "Valle",
      label: "Valle del Cauca",
    },
  ];
  
  export const cities = [
    {
      value: "Seleccione",
      label: "Seleccione una ciudad o municipio",
    },
    {
      value: "Medellin",
      label: "Medellin",
    },
    {
      value: "Cali",
      label: "Cali",
    },
    {
      value: "Barranquilla",
      label: "Barranquilla",
    },
    {
      value: "Bogota",
      label: "Bogota",
    },
  ];
  