import React, { useEffect } from "react";
// import ContactForm from "../../Components/ContactForm/ContactForm";
import { Typography, CircularProgress } from "@mui/material";
import FormularioHubSpotPQR from "../../Components/Form_PQR/Form_PQR";
import "./Contact_PQR.css";
import LazyLoad from 'react-lazy-load';

const Contact_PQR = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <LazyLoad placeholder={<CircularProgress/>}>
    <div className="component_container">
      <Typography sx={{marginY:5}} variant="h4">DILIGENCIA EL SIGUIENTE FORMULARIO</Typography>
      <div className="container_form">
          <FormularioHubSpotPQR/>
      </div>
    </div>
    </LazyLoad>
  );
};

export default Contact_PQR;
