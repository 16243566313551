import React from "react";
import {
  Button,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { CardCover } from "@mui/joy";
import { Link } from "react-router-dom";
import Paqueteria from "../../Assets/Services_Images/paquetes.jpg";
import Alcenamiento from "../../Assets/Services_Images/almacenamiento.jpg";
import Mensajeria from "../../Assets/Services_Images/mensajeria.jpg";
import Card from "@mui/joy/Card";
import "./style.css";

const Services = () => {
  return (
    <>
      <Container className="container_titulo_servicios" sx={{ marginBottom: 5 }}>
        <Typography color="#292D95" variant="h3" fontWeight="bold" fontSize="40px">
          NUESTROS SERVICIOS
        </Typography>
      </Container>
      <Grid
        sx={{ padding: 3 }}
        container
        spacing={12}
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid sx={{ minWidth: 275, minHeight: 330 }} item xs={12} sm={6} md={4}>
          <Card className="image">
            <CardMedia
              image={Paqueteria}
              component="img"
              height="140"
              alt="paquetes"
              className="imagen"
            />
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                component="div"
                className="title-service"
              >
                MENSAJERÍA EXPRESA
              </Typography>
            </CardContent>
            <CardCover className="gradient-cover">
              <Typography sx={{ color: "#fff" }} textAlign="center">
                Trabajamos para llevar al lugar de destino sus documentos,
                productos y paquetes en el menor tiempo posible, con opción de
                entrega directa en su dirección de destino o personalmente al
                destinatario.
              </Typography>
              <Button className="button-general">
                <Link to="mensajeria">Saber más</Link>
              </Button>
            </CardCover>
          </Card>
        </Grid>
        <Grid sx={{ minWidth: 275, minHeight: 330 }} item xs={12} sm={6} md={4}>
          <Card className="image">
            <CardMedia
              image={Mensajeria}
              component="img"
              height="140"
              alt="paquetes"
              className="imagen"
            />
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                component="div"
                className="title-service"
              >
                PAQUETEO
              </Typography>
            </CardContent>
            <CardCover className="gradient-cover">
              <Typography sx={{ color: "#fff" }} textAlign="center">
                Entregamos sus paquetes o mercancías con seguridad, calidad y
                compromiso, velando por el cuidado integral de los mismos.
              </Typography>
              <Button className="button-general">
                <Link to="paqueteo">Saber más</Link>
              </Button>
            </CardCover>
          </Card>
        </Grid>
        <Grid sx={{ minWidth: 275, minHeight: 330 }} item xs={12} sm={6} md={4}>
          <Card className="image">
            <CardMedia
              image={Alcenamiento}
              component="img"
              height="140"
              alt="paquetes"
              className="imagen"
            />
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                component="div"
                className="title-service-inventarios"
                textAlign="center"
                fontSize={16}
              >
                ALMACENAMIENTO Y CONTROL DE INVENTARIOS
              </Typography>
            </CardContent>

            <CardCover className="gradient-cover">
              <Typography sx={{ color: "#fff" }} textAlign="center">
                Administramos con responsabilidad y eficiencia su mercancía
                cumpliendo con el proceso integral de logística, desde la
                nacionalización hasta la entrega al destinatario final.
              </Typography>
              <Button className="button-general" routerLink="almacenamiento">
                <Link to="almacenamiento">Saber más</Link>
              </Button>
            </CardCover>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Services;
