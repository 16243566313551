import React from "react";
import { Box, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import Card from "@mui/joy/Card";
import { CardCover } from "@mui/joy";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import Team from "../../Assets/Select_Images/team.jpg";
import Flag from "../../Assets/Select_Images/flag.jpg";
import Tecno from "../../Assets/Select_Images/tecno.jpg";
import Agilidad from "../../Assets/Select_Images/agile.jpg";

import "./style.css";
import LocalAirportOutlinedIcon from "@mui/icons-material/LocalAirportOutlined";
const SelectSection = () => {
  return (
    <>
      <Box sx={{ marginBottom: 5, backgroundColor: "#585858", paddingY: 5 }}>
        <Typography color="#fff" variant="h3" fontWeight="bold" fontSize="30px">
          ¿POR QUÉ ELEGIRNOS?
        </Typography>
      </Box>
      <Grid
        sx={{ padding: 3 }}
        container
        spacing={12}
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid sx={{ minWidth: 275, minHeight: 370 }} item xs={12} sm={6} md={3}>
          <Card className="image">
            <CardMedia
              image={Team}
              component="img"
              height="140"
              alt="paquetes"
              className="imagen"
            />
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                component="div"
                className="title-service-select"
              >
                PROCESOS EN EQUIPO
                <Diversity3OutlinedIcon className="logo_equipo"/>
              </Typography>
            </CardContent>
            <CardCover className="gradient-cover-select">
              <Typography
                variant="h6"
                component="div"
                className="title-service-select"
              >
                PROCESOS EN EQUIPO
                <Diversity3OutlinedIcon className="logo_equipo"/>
              </Typography>
              <div className="cover_text"> 
              <Typography sx={{ color: "#000" }} textAlign="justify">
                Desarrollamos procesos en compañía de nuestros clientes, ya que
                nuestros modelos son flexibles y permiten ajustarse a los
                requerimientos de cada aliado estratégico.
              </Typography>
              </div>
            </CardCover>
          </Card>
        </Grid>
        <Grid sx={{ minWidth: 275, minHeight: 370 }} item xs={12} sm={6} md={3}>
          <Card className="image">
            <CardMedia
              image={Tecno}
              component="img"
              height="140"
              alt="paquetes"
              className="imagen"
            />
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                component="div"
                className="title-service-select"
                sx={{
                  fontSize: 18
                }}
              >
                INNOVACIÓN TECNÓLOGICA
                <LocalShippingOutlinedIcon className="logo_tecnologia" />
              </Typography>
            </CardContent>
            <CardCover className="gradient-cover-select">
              <Typography
                variant="h6"
                component="div"
                className="title-service-select"
              >
                INNOVACIÓN TECNÓLOGICA
                <LocalShippingOutlinedIcon className="logo_tecnologia" />
              </Typography>
              <Typography sx={{ color: "#000" }} textAlign="justify">
                Contamos con tecnología de punta en alianza con grandes socios
                estratégicos en TI que nos permite realizar la trazabilidad de
                sus envíos en tiempo real y validando a través de la biometría
                la identidad del destinatario evitando fraudes y robos.
              </Typography>
            </CardCover>
          </Card>
        </Grid>
        <Grid sx={{ minWidth: 275, minHeight: 370 }} item xs={12} sm={6} md={3}>
          <Card className="image">
            <CardMedia
              image={Flag}
              component="img"
              height="140"
              alt="paquetes"
              className="imagen"
            />
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                component="div"
                className="title-service-select"
                textAlign="left"
              >
                CUBRIMIENTO NACIONAL
                <LocalAirportOutlinedIcon className="logo" />
              </Typography>
            </CardContent>

            <CardCover className="gradient-cover-select">
              <Typography
                variant="h6"
                component="div"
                className="title-service-select"
                textAlign="left"
              >
                CUBRIMIENTO NACIONAL
                <LocalAirportOutlinedIcon className="logo"/>
              </Typography>
              <div className="cover_text_2"> 
              <Typography sx={{ color: "#000" }} textAlign="justify">
                Nuestra cobertura abarca a más de 1.000 municipios a nivel
                nacional. Con envíos eficientes y agiles
              </Typography>
              </div>
            </CardCover>
          </Card>
        </Grid>
        <Grid sx={{ minWidth: 275, minHeight: 370 }} item xs={12} sm={6} md={3}>
          <Card className="image">
            <CardMedia
              image={Agilidad}
              component="img"
              height="140"
              alt="paquetes"
              className="imagen"
            />
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
              }}
            >
              <Typography
                variant="h6"
                component="div"
                className="title-service-select"
                textAlign="left"
              >
                AGILIDAD EN PROCESOS
                <BusinessCenterOutlinedIcon className="logo_agilidad"/>
              </Typography>
            </CardContent>

            <CardCover className="gradient-cover-select">
              <Typography
                variant="h6"
                component="div"
                className="title-service-select"
                textAlign="left"
              >
                AGILIDAD EN PROCESOS
                <BusinessCenterOutlinedIcon className="logo_agilidad"/>
              </Typography>
              <div className="cover_text_3"> 
              <Typography sx={{ color: "#000" }} textAlign="justify">
                Disponemos de una red robusta que garantiza la distribución de
                sus envíos y documentos con rapidez y oportunidad.
              </Typography>
              </div>
            </CardCover>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default SelectSection;
