import React from "react";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import "./style.css";
import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { WhatsApp } from "@mui/icons-material";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="titulo_footer">
          <h5>SÍGUENOS</h5>
        </div>
        <div className="footer-container-iconos">
          <div className="footer-icons">
            <a href="https://twitter.com/bigsolucionesl1" target="_blank">
              <TwitterIcon color="#fff" />
            </a>
            <a href="https://wa.me/+573102314734" target="_blank">
              <WhatsApp color="#fff" />
            </a>
            <a href="https://www.instagram.com/big_soluciones_logisticas/" target="_blank">
              <InstagramIcon color="#fff" />
            </a>
            <a href="https://www.facebook.com/bslbogota/" target="_blank">
              <FacebookOutlinedIcon color="#fff" />
            </a>
          </div>
        </div>
        <div className="linea_footer"></div>
        <Grid container sx={{marginY:1}}>
          <Grid sx={12} md={6} padding={8}>
            <Box>
              <Typography variant="h6">TÉRMINOS LEGALES</Typography><br></br>
              <Link to="Politica_datos"><a>Política de tratamiento de datos</a></Link><br></br><br></br>
              <Link to="Politica_seguridad_info"><a>Politica de seguridad de la información</a></Link><br></br><br></br>
                <Link to="PQR"><a>PQR</a></Link>
            </Box>
          </Grid>
          
          <Grid sx={12} md={6} padding={8} >
            <Box
              sx={{
                display: "flex",
                alignItems: "end",
                flexDirection: "column",
              }}
            >
              <Typography variant="h6">CONTÁCTENOS</Typography><br></br>
                  <a
                    className="mail"
                    href="mailto:contactenos@bigsoluciones.com"
                  >
                    contactenos@bigsoluciones.com
                  </a>
                <br></br>
                  <a  href={"https://wa.me/+573102314734"} target="_blank" rel="noopener noreferrer" className="numero_cel">+57 310 231 4734</a>
                <br></br>
                  <a className="numero_cel">+ 57 317 365 6813</a>
            </Box>
          </Grid>
        </Grid>
        <div className="linea_footer"></div>
        
        <Box sx={{display:'flex', justifyContent:'center', marginTop:3, paddingX:5}}>
          <Typography className="root" variant="a" >BOGOTÁ D.C. - Carrera 42 # 20 c – 24 piso 3</Typography>
        </Box>
      </div>
      <div className="footer_block">
        <div className="titulo_footer_block">
          <a>
            BSL S.A.S (Big Soluciones Logísticas S.A.S.) Todos los derechos reservados 2024
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
