import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RiTeamFill, RiBuilding4Fill } from "react-icons/ri";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import { Collapse, Menu, MenuItem } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [openItem, setOpenItem] = useState(false);

  const handleClickOpenItem = () => {
    setOpenItem(!openItem);
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const menuOptions = [
    {
      text: "Seguimiento",
      icon: <HomeIcon />,
      path: "seguimiento_guia",
      dropdown: false,
    },
    {
      text: "Nosotros",
      icon: <HomeIcon />,
      path: "about-us",
      dropdown: false,
    },
    {
      text: "Contáctenos",
      icon: <InfoIcon />,
      path: "ubicacion",
      dropdown: false,
    },
    {
      text: "Tarifas",
      icon: <CommentRoundedIcon />,
      path: "tarifas",
      dropdown: false,
    },
    {
      text: "Inicio",
      icon: <ShoppingCartRoundedIcon />,
      path: "/",
      dropdown: false,
    },
    {
      text: "Servicios",
      icon: <PhoneRoundedIcon />,
      dropdown: true,
      types: [
        {
          text: "Paqueteo",
          path: "paqueteo",
        },
        {
          text: "Almacenamiento",
          path: "almacenamiento",
        },
        {
          text: "Mensajería expresa",
          path: "mensajeria",
        },
        {
          text: "Nuestra cobertura",
          path: "cobertura",
        },
      ],
    },
  ];
  return (
    <nav>
      <div className="navbar-links-container">
        <Link to="/">Inicio</Link>
        <a
          // id="basic-button"
          // aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          // aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          Servicios
        </a>
        <Link to="seguimiento_guia">Seguimiento</Link>
        <Link to="about-us">Nosotros</Link>
        <Link to="ubicacion">Contáctenos</Link>
        <Link to="tarifas">Tarifas</Link>
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          // onClick={() => setOpenMenu(false)}
          // onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item) => (
              <ListItem key={item.text} disablePadding>
                {item.dropdown ? (
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <ListItemButton onClick={handleClickOpenItem}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                      {openItem ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openItem} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item.types.map((type, index) => (
                          <ListItemButton key={index} sx={{ pl: 4 }}>
                            <Link
                              to={type.path}
                              onClick={() => setOpenMenu(!openMenu)}
                            >
                              {type.text}
                            </Link>
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  </Box>
                ) : (
                  <Link to={item.path}>
                    <ListItemButton onClick={() => setOpenMenu(false)}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                      {item.dropdown && (
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem onClick={handleClose}>
                            <Link sx={{ textDecoration: "none" }} to="paqueteo">
                              Paqueteo
                            </Link>
                          </MenuItem>
                          <MenuItem onClick={handleClose}>
                            <Link
                              sx={{ textDecoration: "none" }}
                              to="almacenamiento"
                            >
                              Almacenamiento y control de inventarios
                            </Link>
                          </MenuItem>
                          <MenuItem onClick={handleClose}>
                            <Link
                              sx={{ textDecoration: "none" }}
                              to="mensajeria"
                            >
                              Mensajería Expresa
                            </Link>
                          </MenuItem>
                        </Menu>
                      )}
                    </ListItemButton>
                  </Link>
                )}
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <Link sx={{ textDecoration: "none" }} to="paqueteo">
            Paqueteo
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link sx={{ textDecoration: "none" }} to="almacenamiento">
            Almacenamiento y control de inventarios
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link sx={{ textDecoration: "none" }} to="mensajeria">
            Mensajería Expresa
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link sx={{ textDecoration: "none" }} to="cobertura">
            Nuestra cobertura
          </Link>
        </MenuItem>
      </Menu>
      <Link to="https://informacion.bigsoluciones.com/tickets-view" className="primary-button-2" target="_blank">
        PORTAL CLIENTES
        <RiTeamFill className="logo_xue" size={13} />
      </Link>
      <Link to="https://xue.bigsoluciones.com/" className="primary-button" target="_blank">
        XUE
        <RiBuilding4Fill className="logo_xue" size={13} />
      </Link>
    </nav>
  );
};

export default Navbar;
