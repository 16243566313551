import React, { useEffect } from "react";
import { Box, Button, Grid, List, ListItem, Typography } from "@mui/material";
import Warehouse from "../../Assets/Storage_Images/warehouse.jpg";
import Delivery from "../../Assets/Storage_Images/mensajeria.jpg";
import "./style.css";
import { Link } from "react-router-dom";

const StoragePage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container item className="container-info">
      <Grid xs={12} sm={6} md={6}>
      <div className="container_general_storage">
        <div className="Titulo_1_storage">
        <Typography variant="h4" sx={{ color: "#4548A3", fontWeight: "bold", fontSize: 25}}>
          ALMACENAMIENTO
        </Typography>
        </div><br></br>
        <Typography variant="body1" className="container_texto_storage" sx={{ paddingY: 2, lineHeight: 1.5 }}>
          Para tu empresa, BSL te ofrece un servicio integral de inventario
          caracterizado por su agilidad y trazabilidad. En este nos centramos
          en:
        </Typography>
        </div>
        <div className="container_lista_storage">
        <List>
          <ListItem className="item_lista">
            Almacenamiento, alistamiento y control de inventarios.
          </ListItem>
          <ListItem>
            Administración integral de sus procesos logísticos.
          </ListItem>
          <ListItem>
            Diseño de su operación logística de acuerdo a sus necesidades.
          </ListItem>
        </List>
        </div>
        <div className="boton">
        <Link to="/ubicacion">
        <Button className="button-general">
          Contáctanos
        </Button>
        </Link>
        </div>
      </Grid>
      <Grid xs={12} sm={5} md={5}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <img
            className="images-paqueteo"
            src={Warehouse}
            alt="trucks delivery"
          />
          <img
            className="images-paqueteo image2-paqueteo"
            src={Delivery}
            alt="containers delivery"
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default StoragePage;
