import React from "react";
import { FiArrowRight } from "react-icons/fi";
import Banner_Logo from "../../Assets/Banner_home/Banner_logo.png";
import Banner_trabajador from "../../Assets/Banner_home/Banner_trabajador.png";
import BannerContainers from "../../Assets/Banner_home/Banner_Containers.jpg";
import { Link } from "react-router-dom";
import { Typography, CircularProgress } from "@mui/material";
import './SectionBanner.css';
import LazyLoad from 'react-lazy-load';

const SectionBanner = ({ title, description }) => {
  return (
    <>
      <div className={description?"home-banner-container":"container-about"}>
        <div className="home-text-section">
          {description && (
            <h1 className="primary-heading">
              <LazyLoad placeholder={<CircularProgress/>}>
              <img src={Banner_Logo} alt="" />
              </LazyLoad>
            </h1>
          )}
          <p className={description?"primary-text":"title-about"}>{title}</p>
          {!description ? (
            <div className="description-about">
              <p>
                BSL S.A.S (Big Soluciones Logísticas S.A.S.), Es una empresa
                colombiana de logística, constituida en la Cámara de Comercio de
                Bogotá, el 20 de Junio de 2005.
              </p>
              <p>
                Hoy BSL S.A.S., es una excelente alternativa para los
                empresarios que requieren un soporte logístico con valor
                agregado para sus clientes, en el cumplimiento de los estándares
                de calidad de su compañía.
              </p>
            </div>
          ) : (
            <Link to="/seguimiento_guia">
            <div className="container_boton_seguimiento">
            <button className="secondary-button">
              Consultar guía <FiArrowRight />{" "}
            </button>
            </div>
            </Link>
          )}
        </div>
        <div className="home-image-section">
        {
          !description?<img src={BannerContainers} alt="" />:<img src={Banner_trabajador} alt="" />
        }
          
        </div>
      </div>
    </>
  );
};

export default SectionBanner;
