import React, { useEffect } from "react";
import { Box, Button, Grid, List, ListItem, Typography } from "@mui/material";
import Mensajeria from "../../Assets/Delivery_Images/mensajeria.jpg";
import Paquetes from "../../Assets/Delivery_Images/paquetes.jpg";
import "./style.css";
import { Link } from "react-router-dom";

const Politica_seguridad_info = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <>
      <div className="container_general_politica">
        <div className="Titulo">
        <Typography variant="h4" sx={{ color: "#4548A3", fontWeight: "bold" }}>
          Políticas para la seguridad de la informacion
        </Typography>
        </div>
        <Typography variant="body1" sx={{ paddingY: 5, lineHeight: 2.5, marginBottom: 30 }}>
        BIG SOLUCIONES LOGISTICAS S.A.S Compañía prestadora de servicio de mensajería expresa y logística, almacenamiento, paqueteo e impresión a nivel nacional y urbano, cuenta con un Sistema de Gestión de Seguridad de la Información (SGSI) para controlar la fuga de datos de tarjeta habiente e información crítica de nuestras partes interesadas preservando su confidencialidad, integridad y disponibilidad; esto mediante prácticas de mejoramiento continuo, gestión del riesgo, satisfacción de los requisitos del cliente y el cumplimiento de los requisitos legales y reglamentarios que aplique al Sistema de Gestión de Seguridad de la Información (SGSI)
        </Typography>
        </div>
        <div className="bloque">
        </div>
        </>
  );
};

export default Politica_seguridad_info;