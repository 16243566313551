import React from "react";
import { Typography } from "@mui/material";
import "./style.css";
const TypeContact = ({ title, icon, path, color }) => {
  return (
    <div className="container-type-icons">
      {color ? (

        <div className="icons-types container-type-icon">{icon}</div>
      ) : (
        <a href={path} className="container-type-icon">
          <div className="icons-types">{icon}</div>
        </a>
      )}

      <Typography variant="h6" sx={{ color: `${color}` }}>
        {title}
      </Typography>
    </div>
  );
};

export default TypeContact;
