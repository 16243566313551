import React from 'react'
import Logo_nequi from '../../Assets/Slider_clientes/Logo_nequi.png';
import Logo_konecta from '../../Assets/Slider_clientes/Logo_konecta.png';
import Logo_bancol from '../../Assets/Slider_clientes/Logo_bancol.png';
import Logo_copiloto from '../../Assets/Slider_clientes/Logo_copiloto.png';
import Logo_credivan from '../../Assets/Slider_clientes/Logo_credivan.png';
import Logo_falabella from '../../Assets/Slider_clientes/Logo_falabella.png';
import Logo_teleperformance from '../../Assets/Slider_clientes/Logo_teleperformance.png';
import Logo_tuya from '../../Assets/Slider_clientes/Logo_tuya.png';
import Logo_credifinanciera from '../../Assets/Slider_clientes/Logo_credifinanciera.png';

const Slider = () => {
  return (
    <div className='marco_slider'><br></br><br></br>
        <div className='texto_carrusel'>
        <h4>NUESTROS CLIENTES</h4>
        <div className='linea'></div>
        </div>
        <div class="slider">
        <div class="slide-track">
            <div class="slide">
                <img src={Logo_nequi} alt=""/>
            </div>
            <div class="slide">
                <img src={Logo_bancol} alt=""/>
            </div>
            <div class="slide">
                <img src={Logo_falabella} alt=""/>
            </div>
            <div class="slide">
                <img src={Logo_konecta} alt=""/>
            </div>
            <div class="slide">
                <img src={Logo_copiloto} alt=""/>
            </div>
            <div class="slide">
                <img src={Logo_credivan} alt=""/>
            </div>
            <div class="slide">
                <img src={Logo_teleperformance} alt=""/>
            </div>
            <div class="slide">
                <img src={Logo_tuya} alt=""/>
            </div>
            <div class="slide">
                <img src={Logo_credifinanciera} alt=""/>
            </div>


            <div class="slide">
                <img src={Logo_nequi} alt=""/>
            </div>
            <div class="slide">
                <img src={Logo_bancol} alt=""/>
            </div>
            <div class="slide">
                <img src={Logo_falabella} alt=""/>
            </div>
            <div class="slide">
                <img src={Logo_konecta} alt=""/>
            </div>
            <div class="slide">
                <img src={Logo_copiloto} alt=""/>
            </div>
            <div class="slide">
                <img src={Logo_credivan} alt=""/>
            </div>
            <div class="slide">
                <img src={Logo_teleperformance} alt=""/>
            </div>
            <div class="slide">
                <img src={Logo_tuya} alt=""/>
            </div>
            <div class="slide">
                <img src={Logo_credifinanciera} alt=""/>
            </div>
        </div>
    </div>  
</div>
  )
}

export default Slider
