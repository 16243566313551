import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import Information from "../../Components/Information/Information";
import Map from "../../Components/Map/Map";
import TypeContact from "../../Components/Type-Contact/TypeContact";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import "./Ubication.css"

const Ubication = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <div className="container_component_ubication">
    <Typography variant="h4" sx={{ marginY: 3}}>
        ¿DÓNDE ENCONTRARNOS?
      </Typography>
      <Grid
        container
        spacing={12}
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{
          padding: 3,
          marginX: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="map_container_ubication">
        <Grid sx={{ maxWidth: 275, minHeight: 280 }} item xs={12} sm={6} md={4}>
          <Map />
        </Grid>
        </div>
        <div className="info_container_ubication">
        <Grid xs={12} sm={6} md={6}>
          <Information />
        </Grid>
        </div>
      </Grid>
      </div>
      <div className="linea"></div><br></br>
      <Typography variant="h4" sx={{ marginY: 5, fontSize: 25}}>
        ¿CUÁL ES TU MOTIVO DE CONTACTO?
      </Typography><br></br><br></br>
      <Grid container sx={{marginBottom:10}}>
        <Grid xs={0} sm={3} md={4}>
        </Grid>
        <Grid xs={6} sm={3} md={2}>
          <TypeContact
            title="COTIZACIONES"
            path="/cotizaciones"
            icon={<AttachMoneyIcon />}
          />
        </Grid>
        <Grid xs={6} sm={3} md={2}>
          <TypeContact 
          title="PQR"
           path="/PQR" 
           icon={<SupportAgentIcon />} />
        </Grid>
        {/* <Grid xs={12} sm={6} md={4}>
          <TypeContact
            title="PORTAL CLIENTES"
            path="#"
            icon={<ImportantDevicesIcon/>}
          />
        </Grid> */}
      </Grid>
    </>
  );
};

export default Ubication;
