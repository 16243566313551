import React, { useEffect } from "react";
// import Banner_trabajador from "../../Assets/Banner_home/Banner_trabajador.png";
// import Banner_logo from "../../Assets/Banner_home/Banner_logo.png";
import Slider from "../../Components/Slider/Slider";
import SectionBanner from "../../Components/SectionBanner/SectionBanner";
import Services from "../../Components/ServicesSection/Services";
import SelectSection from "../../Components/SelectSection/SelectSection";
import "./Home.css";
// import { FiArrowRight } from "react-icons/fi";

const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home-container">
      {/* <Navbar /> */}
      <SectionBanner title="En BSL contamos con la tecnología necesaria para realizar tus entregas de manera rápida y segura a más de 1000 municipios a nivel nacional." description={true}/>
      <Slider />
      <Services/>
      <SelectSection/>
      {/* <Footer /> */}
    </div>
  );
};

export default Home;
