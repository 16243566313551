import React from "react";
import "./AboutBanner.css";
import LazyLoad from "react-lazy-load";
import { Typography, CircularProgress } from "@mui/material";

const AboutBanner = () => {
  return (
    <LazyLoad placeholder={<CircularProgress/>}>
    <div className="containerAbout">
      <div className="containerInfo">
        <div className="titulo_aboutUs">
        <h2 className="titulo_aboutUS">¿QUIÉNES SOMOS?</h2>
        </div>
        <p className="texto">
          BSL S.A.S (Big Soluciones Logísticas S.A.S.) es una empresa
          colombiana de logística, constituida en la Cámara de Comercio de
          Bogotá, el 20 de Junio de 2005.
        </p>
        <p className="texto">
        Hoy BSL S.A.S. Compañía certificada en PCI – DSS, es una excelente alternativa para los empresarios
        que requieren un soporte logístico con valor agregado para sus clientes, en el cumplimiento de los
        estándares de calidad de su compañía.
        </p>
      </div>
    </div>
    </LazyLoad>
  );
};

export default AboutBanner;
