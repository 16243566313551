import React from 'react'
import { useEffect } from 'react';
import AboutBanner from '../../Components/AboutBanner/AboutBanner'
import MisionVision from '../../Components/MisionVision/MisionVision'
import ValuesInfo from '../../Components/ValuesInfo/ValuesInfo'

const AboutUs = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
     <AboutBanner/>   
     <MisionVision/>
     <ValuesInfo/>
    </>
  )
}

export default AboutUs