import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./Pages/Home/Home";
import Paqueteo from "./Pages/Paqueteo/Package";
import Storage from "./Pages/Storage/Storage";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import DeliveryExpressPage from "./Pages/Delivery/Delivery";
import Ubication from "./Pages/Ubication/Ubication";
import Contact from "./Pages/Contact/Contact";
import Contact_PQR from "./Pages/Contact_PQR/Contact_PQR";
import Contact_Cotizaciones from "./Pages/Contact_Cotizacion/Contact_Cotizaciones";
import AboutUs from "./Pages/AboutUs/AboutUs";
import Coverage from "./Pages/CoveragePage/Coverage";
import Seguimiento_guia from "./Pages/Seguimiento_guia/Seguimiento_guia";
import Tarifas from "./Pages/Tarifas/Tarifas";
import Politica_datos from "./Pages/Politica_datos/Politica_datos";
import Politica_seguridad_info from "./Pages/Politica_seguridad_info/Politica_seguridad_info";
function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />

        <Routes>
          {/* <Navbar /> */}
          <Route exact path="/" element={<Home />} />
          <Route exact path="paqueteo" element={<Paqueteo />} />
          <Route exact path="almacenamiento" element={<Storage />} />
          <Route exact path="mensajeria" element={<DeliveryExpressPage />} />
          <Route exact path="ubicacion" element={<Ubication />} />
          <Route exact path="contacto" element={<Contact />} />
          <Route exact path="PQR" element={<Contact_PQR />} />
          <Route exact path="cotizaciones" element={<Contact_Cotizaciones />} />
          <Route exact path="about-us" element={<AboutUs />} />
          <Route exact path="cobertura" element={<Coverage />} />
          <Route exact path="seguimiento_guia" element={<Seguimiento_guia />} />
          <Route exact path="tarifas" element={<Tarifas />} />
          <Route exact path="Politica_datos" element={<Politica_datos />} />
          <Route exact path="Politica_seguridad_info" element={<Politica_seguridad_info />} />
        </Routes>
        <Footer />
      </Router>
      {/* <Home/> */}
    </div>
  );
}

export default App;
