import React, { useEffect } from "react";
import CoverageInfo from "../../Components/CoverageInfo/CoverageInfo";

const Coverage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CoverageInfo />
    </>
  );
};

export default Coverage;
