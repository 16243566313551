import React from "react";
import { Box, Button, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";


import "./style.css";
import { citiesOrDepartment, idDocument } from "../../helpers/Information";

const ContactForm = () => {
  return (
    <Box
      component="form"
      autoComplete="off"
      sx={{
        marginY: 10,
        display: "flex",
        flexDirection: "column",
        paddingX: 10,
        gap: 3,
      }}
    >
      <TextField
        autoFocus
        label="Nombres y apellidos *"
        placeholder="Nombres y apellidos *"
      />
      <TextField
        id="outlined-select-currency-native"
        select
        label="Seleccione el tipo de documento *"
        defaultValue="CC"
        SelectProps={{
          native: true,
        }}
        helperText="Seleccione el tipo de documento *"
      >
        {idDocument.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
      <TextField
        required
        id="outlined-select-currency-native"
        select
        label="Seleccione el tipo de persona"
        defaultValue="CC"
        SelectProps={{
          native: true,
        }}
        helperText="Seleccione el tipo de persona"
      >
        {idDocument.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
      <TextField
        required
        type="email"
        label="Correo electrónico"
        placeholder="Correo electrónico "
      />
      <TextField required label="Teléfono" placeholder="Teléfono " />
      <TextField
        required
        id="outlined-select-currency-native"
        select
        label="Ciudad o municipio"
        defaultValue="CC"
        SelectProps={{
          native: true,
        }}
        helperText="Ciudad o municipio"
      >
        {citiesOrDepartment.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
      <TextField multiline label="Mensaje" placeholder="Mensaje" />
      <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
        <Button
          className="button-form"
          endIcon={<SendIcon />}
          variant="contained"
        >
          Enviar
        </Button>
      </Box>
    </Box>
  );
};

export default ContactForm;
