import React from 'react';

class FormularioHubSpotPQR extends React.Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.charset = 'utf-8';
    script.type = 'text/javascript';
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '23479224',
        formId: '178d3166-fbf6-4d9f-a382-9d3ad9326ffe',
        target: '#formulario-hubspot'
      });
    };
  }

  componentWillUnmount() {
    const script = document.querySelector('script[src="//js.hsforms.net/forms/embed/v2.js"]');
    document.body.removeChild(script);
  }

  render() {
    return (
      <div id="formulario-hubspot">
        {/* El formulario de HubSpot se mostrará aquí */}
        <style>
          {`
            /* Estilos personalizados */
            #formulario-hubspot input {
              padding: 10px;
              border: 1px solid #ccc;
              border-radius: 4px;
              background-color: #FF0000;
            }
            
            #formulario-hubspot button {
              background-color: #FF0000;
              color: #fff;
              padding: 10px 20px;
              border: none;
              border-radius: 4px;
            }
          `}
        </style>
      </div>
    );
  }
}

export default FormularioHubSpotPQR;