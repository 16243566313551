import React, { useEffect } from "react";
import { Box, Button, Grid, List, ListItem, Typography } from "@mui/material";
import Mensajeria from "../../Assets/Delivery_Images/mensajeria.jpg";
import Paquetes from "../../Assets/Delivery_Images/paquetes.jpg";
import "./style.css";
import { Link } from "react-router-dom";

const Politica_datos = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <>
      <div className="container_general_politica">
        <div className="Titulo">
        <Typography variant="h4" sx={{ color: "#4548A3", fontWeight: "bold" }}>
          Políticas para el Tratamiento de Datos Personales
        </Typography>
        </div>
        <Typography variant="body1" sx={{ paddingY: 2, lineHeight: 1.5 }}>
        La presente página contiene las políticas Big Soluciones Logísticas S.A.S, con sede en la Cra. 42 No. 20 C – 24 Piso 3, en la ciudad de Bogotá D.C., Colombia, para el tratamiento de información personal de quienes se contactan para recibir información sobre nuestros productos o servicios de quienes mantienen alguna relación comercial o informativa con la empresa.
        </Typography>
        <div className="Titulo">
        <Typography variant="h4" sx={{ color: "#4548A3", fontWeight: "semibold" }}>
        Objetivos y principios generales
        </Typography>
        </div>
        <Typography variant="body1" sx={{ paddingY: 2, lineHeight: 1.5 }}>
        La empresa Big Soluciones Logísticas S.A.S, en desarrollo del artículo 15 de la Constitución Política y en cumplimiento de las leyes 1266 de 2008, 1581 de 2012 y su decreto reglamentario 1377 de 2013, busca garantizar los derechos que tienen las personas a conocer, actualizar y rectificar las informaciones que se hayan recogido sobre ellas en las bases de datos personales y demás archivos que utiliza.
        </Typography>
        <Typography variant="body1" sx={{ paddingY: 2, lineHeight: 1.5 }}>
        Los titulares de las presentes Políticas, serán aquellas personas que tengan una relación comercial o informativa con Big Soluciones Logísticas S.A.S, ya sea permanente u ocasional.
        </Typography>
        <Typography variant="body1" sx={{ paddingY: 2, lineHeight: 1.5 }}>
        Big Soluciones Logísticas S.A.S es el encargado y responsable del tratamiento de la información que repose en sus bases de datos personales, entendiéndose tratamiento como la recolección, almacenamiento, uso, circulación o supresión de los mismos.
        </Typography>
        <div className="Titulo">
        <Typography variant="h4" sx={{ color: "#4548A3", fontWeight: "semibold" }}>
        Tratamiento y finalidad de los datos
        </Typography>
        </div>
        <Typography variant="body1" sx={{ paddingY: 2, lineHeight: 1.5 }}>
        Los datos ingresan a una base de datos que reposa en los servidores de Big Soluciones Logísticas S.A.S o de sus proveedores tecnológicos. La empresa garantiza que la infraestructura, hardware y software usados son los apropiados para almacenar y manejar de una forma segura y confiable la información de quienes le entregan sus datos personales. Los titulares de los datos pueden acceder a sus datos a través de páginas seguras que se conectan a la base de datos, tal como se describe más adelante en el apartado de procedimientos para el ejercicio de los derechos.
        </Typography>
        <Typography variant="body1" sx={{ paddingY: 2, lineHeight: 1.5 }}>
        La finalidad de la captura y la tenencia de datos personales es poder contactar a los usuarios con el fin de proveerles la información que requieren sobre nuestros productos o servicios e información sobre cualquier aspecto de nuestra empresa. Big Soluciones Logísticas S.A.S no comercializará, venderá ni alquilará la información contenida en sus bases de datos personales y que no la transfiere a terceros con fines promocionales ni comerciales.
        </Typography>
        <div className="Titulo">
        <Typography variant="h4" sx={{ color: "#4548A3", fontWeight: "semibold" }}>
        Principios para el tratamiento de datos personales
        </Typography>
        </div>
        <Typography variant="body1" sx={{ paddingY: 2, lineHeight: 1.5 }}>
        De acuerdo con la Ley 1581 de 2012 “por la cual se dictan disposiciones generales para la protección de datos personales”, se aplicarán los siguientes principios el tratamiento de los datos personales, definidos acorde en la ley.
        </Typography>
      </div>
      <div className="container_lista_politica">
        <List>
          <ListItem>
          a- Principio de legalidad en materia de tratamiento de datos.
          </ListItem>
          <ListItem>
          b-  Principio de finalidad
          </ListItem>
          <ListItem>
          c-  Principio de libertad
          </ListItem>
          <ListItem>
          d-  Principio de veracidad o calidad
          </ListItem>
          <ListItem>
          e-  Principio de transparencia
          </ListItem>
          <ListItem>
          f-  Principio de acceso y circulación restringida
          </ListItem>
          <ListItem>
          g-  Principio de seguridad
          </ListItem>
          <ListItem>
          h-  Principio de confidencialidad
          </ListItem>
        </List>
        </div>
        <div className="container_general_politica">
          <div className="Titulo">
            <Typography variant="h4" sx={{ color: "#4548A3", fontWeight: "semibold" }}>
            Derechos que le asisten al titular de la información
            </Typography>
          </div>
          <Typography variant="body1" sx={{ paddingY: 2, lineHeight: 1.5 }}>
          El Titular de los datos personales tendrá los siguientes derechos.
          </Typography>
        </div>
        <div className="container_lista_politica">
        <List>
          <ListItem>
          a- Conocer, actualizar y rectificar sus datos personales frente a Big Soluciones Logísticas S.A.S. Este derecho se podrá ejercer, entre otros frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo Tratamiento esté expresamente prohibido o no haya sido autorizado.
          </ListItem>
          <ListItem>
          b- Solicitar prueba de la autorización otorgada a Big Soluciones Logísticas S.A.S salvo cuando expresamente se exceptúe como requisito para el Tratamiento, de conformidad con lo previsto en el artículo 10 de la ley 1581.
          </ListItem>
          <ListItem>
          c- Ser informado por Big Soluciones Logísticas S.A.S, previa solicitud, respecto del uso que le ha dado a sus datos personales.
          </ListItem>
          <ListItem>
          d- Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la ley 1581 y las demás normas que la modifiquen, adicionen o complementen.
          </ListItem>
          <ListItem>
          e- Revocar la autorización y/o solicitar la supresión del dato cuando en el Tratamiento no se respeten los principios, derechos y garantías constitucionales y legales. La revocatoria y/o supresión procederá cuando la Superintendencia de Industria y Comercio haya determinado que en el Tratamiento el Responsable o Encargado han incurrido en conductas contrarias a la ley y a la Constitución.
          </ListItem>
          <ListItem>
          f- Acceder en forma gratuita a sus datos personales que hayan sido objeto de Tratamiento.
          </ListItem>
        </List>
        </div>
        <div className="bloque">
        </div>
        </>
  );
};

export default Politica_datos;
