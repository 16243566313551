import React, { useEffect } from "react";
// import ContactForm from "../../Components/ContactForm/ContactForm";
import { Typography, CircularProgress} from "@mui/material";
import FormularioHubSpotPQR from "../../Components/Form_cotizaciones/Form_cotizaciones";
import "./Seguimiento_guia.css";
import LazyLoad from 'react-lazy-load';

const Seguimiento_guia = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <LazyLoad placeholder={<CircularProgress/>}>
    <div className="component_container">
      <Typography sx={{marginY:5}} variant="h4">RASTREA TU ENVIÓ</Typography>
      <div>
      {/* Otro contenido de tu aplicación de React */}
      <iframe src="https://xue.bigsoluciones.com/cliente/consulta.aspx" width="100%" height="500px" title="Página ASPX"></iframe>
      {/* Otro contenido de tu aplicación de React */}
      </div>
    </div>
    </LazyLoad>
  );
};

export default Seguimiento_guia;