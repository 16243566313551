import React from "react";
import { useEffect } from 'react';
import ContactForm from "../../Components/ContactForm/ContactForm";
import { Typography } from "@mui/material";

const Contact = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Typography sx={{marginY:5}} variant="h4">DILIGENCIA EL SIGUIENTE FORMULARIO</Typography>
      <ContactForm />
    </div>
  );
};

export default Contact;
