import React, { useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import Vans from "../../Assets/Package_Images/vans.jpg";
import Containers from "../../Assets/Package_Images/containers.jpg";
import { Link } from "react-router-dom";
import "./style.css";

const PackagePage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container item className="container-info">
      <Grid xs={12} sm={6} md={6}>
      <div className="container_general_storage">
        <div className="Titulo_1_paqueteo">
        <Typography variant="h4" sx={{ color: "#4548A3", fontWeight: "bold", fontSize: 24 }}>
          PAQUETEO
        </Typography>
        </div>
        <Typography variant="body1" className="container_texto_paqueteo" sx={{ paddingY: 5, lineHeight: 1.5 }}>
          Cámaras en cabina, bodega interior del vehículo y de vista frontal
          para observar permanentemente lo que está sucediendo dentro y fuera
          del transporte. Comunicación vía micrófono en tiempo real con el
          conductor. Bodega independiente del habitáculo del conductor. Reja de
          seguridad en la parte posterior de la bodega la cual solo puede ser
          abierta satelitalmente.
        </Typography>
      </div>
      <div className="boton">
        <Link to="/ubicacion">
        <Button className="button-general">Contáctanos</Button>
        </Link>
      </div>
      </Grid>
      <Grid xs={12} sm={5} md={5}>
        <Box sx={{ display: "flex", flexDirection: "column", position:'relative'}}>
          <img className="images-paqueteo" src={Vans} alt="trucks delivery" />
          <img
            className="images-paqueteo image2-paqueteo"
            src={Containers}
            alt="containers delivery"
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default PackagePage;
