import React from "react";
import { Container, Typography, Link } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "./style.css";
import PhoneIcon from "@mui/icons-material/Phone";

const Information = () => {
  return (
    <Container className="container-informaction">
      <Typography sx={{ marginY: 3 }}>Bogotá D.C. - Colombia</Typography>
      <Typography>Carrera 42 #20c - 24 piso 3</Typography>
      <a
        className="mail-information"
        href="mailto:contactenos@bigsoluciones.com"
      >
        contactenos@bigsoluciones.com
      </a>
      <Typography
        href={"https://wa.me/+573182380278"}
        target="_blank"
        rel="noopener noreferrer"
      >
        +57 310 231 4734
        <Link
          href={"https://wa.me/+573102314734"}
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginLeft: 14, marginRight: 5 }}
        >
          <WhatsAppIcon sx={{ color: "green" }} />
        </Link>
      </Typography>
      <Typography sx={{ marginY: 3 }}>
        + 57 317 365 6813
        <PhoneIcon sx={{ color: "black", marginLeft: 2 }} />
      </Typography>
    </Container>
  );
};

export default Information;
