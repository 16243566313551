import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Grid, TextField, Typography, MenuItem } from "@mui/material";
import Swal from "sweetalert2";
import "./style.css";
import { cities, departments } from "../../helpers/Information";
import MapCoverage from "../MapCoverage/MapCoverage";
import AWS from 'aws-sdk';
import axios from 'axios';


const showModal = (coberturaLength) => {
  const COVERAGE_MESSAGE = "Si, hay cobertura";
  const NO_COVERAGE_MESSAGE = "No hay cobertura";
  const COVERAGE_SUBMESSAGE = "*Cobertura sujeta a tipo de mercancia o acuerdo comercial"
  const NO_COVERAGE_SUBMESSAGE = "Lo sentimos";
  setTimeout(() => {
  Swal.fire({
    title: coberturaLength > 0 ? COVERAGE_MESSAGE : NO_COVERAGE_MESSAGE,
    text: coberturaLength > 0 ? COVERAGE_SUBMESSAGE : NO_COVERAGE_SUBMESSAGE,
    icon: "info",
    confirmButtonText: "OK",
  });
  }, 4000);
};

const CoverageInfo = () => {

  const [department, setDepartment] = useState("");
  const [city, setCity] = useState("");
  const [coberturaLength, setCoberturaLength] = useState(0);
  const [errorDepartment, setErrorDepartment] = useState("");
  const [errorCity, setErrorCity] = useState("");
  const [deptData, setDeptData] = useState([]);
  const [encontrarMunicipio, setEncontrarMunicipio] = useState([]);
  const selectRef = useRef(null);

  const showModal = (coberturaLength1) => {
    const COVERAGE_MESSAGE = "Si hay cobertura";
    const NO_COVERAGE_MESSAGE = "No hay cobertura";
    const COVERAGE_SUBMESSAGE = "*Cobertura sujeta a tipo de mercancia o acuerdo comercial"
    const NO_COVERAGE_SUBMESSAGE = "Lo sentimos";
    // setTimeout(() => {
    Swal.fire({
      title: coberturaLength1 > 0 ? COVERAGE_MESSAGE : NO_COVERAGE_MESSAGE,
      text: coberturaLength1 > 0 ? COVERAGE_SUBMESSAGE : NO_COVERAGE_SUBMESSAGE,
      icon: "info",
      confirmButtonText: "OK",
    });
    // }, 4000);
  };  

  //Request API departamentos

AWS.config.update({
  accessKeyId: 'AKIAQ32H6HJSEI4NKYO3',
  secretAccessKey: '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',
  region: 'us-east-1',
});

// const parametro_1 = department.toString;

// const parametro_2 = city.toString;

const parametro_1 = "1";

const parametro_2 = "1";

const apiEndpoint = 'https://tsudj0jii5.execute-api.us-east-1.amazonaws.com/Prod/negociaciones/coberturas/departamentos';

// const apiEndpoint2 = 'https://tsudj0jii5.execute-api.us-east-1.amazonaws.com/Prod/negociaciones/coberturas?departamento_id='+parametro_1+'&municipio_id='+parametro_2;

const apiEndpoint2 = 'https://tsudj0jii5.execute-api.us-east-1.amazonaws.com/Prod/negociaciones/coberturas?departamento_id='+department+'&municipio_id='+city;

const fetchData = async () => {
  try {
    const response = await axios.get(apiEndpoint);
    // console.log(response.data.departamentos);
    const data2 = await response.data.departamentos.departamentos;
    setDeptData(await data2);
    console.log(data2)
    console.log(deptData[0].nombre);
    deptData.map((value)=>{console.log(value)})
    console.log("Hola");
   } catch (error) {
    console.error(error);
  }
};

const requestDept = async () => {
  try {
    const response = await axios.get(apiEndpoint2);
    const Length = response.data.coberturas.length;
    setCoberturaLength(Length);
    console.log(response.data.coberturas.length);
    console.log(coberturaLength);
    console.log(typeof coberturaLength);
    showModal(Length);
  } catch (error) {
    console.error(error);
  }
};

function verificacionDept (input){
  const municipio_c = input.toString();
  console.log(municipio_c);
  setCity(municipio_c);
  console.log(city);
  console.log(typeof city);
  // requestDept();
}

async function encontrarM (input){
  const departamento_c = input.toString();
  console.log(departamento_c);
  setDepartment(departamento_c);
  console.log(input);
  console.log(department);
  // console.log(deptData);
  const encontrarMunicipio2 = await deptData.find(({departamento_id})=>departamento_id==input);
  console.log(encontrarMunicipio2);
  // debugger
  // console.log(department);
  setEncontrarMunicipio(await encontrarMunicipio2.municipios);
}

useEffect(() => {

  fetchData();

}, [])

  const validForm = () => {
    if (department === 0 || department === "") {
      setErrorDepartment("Ingrese el departamento");
      return;
    }
    setErrorDepartment("");
    if (city === 0 || city === "") {
      setErrorCity("Ingrese el municipio o ciudad");
      return;
    }
    setErrorCity("");
    requestDept();
    // showModal();
  };
  return (
    <Grid item container sx={{ paddingY: 5, paddingX: 2 }}>
      <Grid xs={12} sm={6} md={6}>
       <MapCoverage/>
      </Grid>
      <div className="container_cobertura_general">
      <Grid
        xs={12}
        sm={6}
        md={6}
        sx={{ display: "flex", flexDirection: "column", gap: 3 }}
      >
        <div className="container_titulo_cobertura">
        <Typography variant="h4" sx={{ fontSize:30, }}>
          NUESTRA COBERTURA
        </Typography>
        </div>
        <div className="container_texto_cobertura">
        <Typography variant="body1" sx={{ fontSize: 20, lineHeight: 1.2 }}>
          Nuestra cobertura se extiende a más de 1000 municipios a nivel
          nacional, con entregas eficientes y ágiles de tus paquetes.
        </Typography>
        </div>
        <div className="container_texto_cobertura">
        <Typography variant="body1" sx={{ fontSize: 20, lineHeight: 1.2 }}>
          Puedes verificar tu municipio de interés aqui:
        </Typography>
        </div>

        <div className="container_titulo2_cobertura">
        <Box
          component="form"
          autoComplete="off"
          sx={{
            marginY: 3,
            display: "flex",
            flexDirection: "column",
            paddingX: 10,
            gap: 3,
          }}
        >
          {errorDepartment && (
            <span className="text-danger">{errorDepartment}</span>
          )}
          <TextField
            // ref={selectRef}
            required
            id="outlined-select-currency-native"
            select
            label="Seleccione departamento"
            defaultValue="Seleccione"
            SelectProps={{
              native: true,
            }}
            helperText="Departamento"
            // error={department.length <= 0}
            onChange={(input) => encontrarM(input.target.value)}
          >
            <option value=""></option>

            {deptData.map((option) => (
              <option key={option.nombre} value={option.departamento_id}>
                {option.nombre}
              </option>
            ))}

          </TextField>
          {errorCity && <span className="text-danger">{errorCity}</span>}
          <TextField
            required
            id="outlined-select-currency-native"
            select
            label="Seleccione municipio"
            defaultValue="Seleccione"
            SelectProps={{
              native: true,
            }}
            helperText="Municipio"
            onChange={(input) => verificacionDept(input.target.value)}
          >
            <option value=""></option>

            {encontrarMunicipio.map((option) => (
              <option key={option.nombre_municipio} value={option.municipio_id}>
                {option.nombre_municipio}
              </option>
            ))}

          </TextField>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              className="button-form"
              variant="contained"
              onClick={validForm}
            >
              Buscar
            </Button>
          </Box>
        </Box>
        </div>
      </Grid>
      </div>
    </Grid>
  );
};

export default CoverageInfo;
