import React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import TypeContact from "../Type-Contact/TypeContact";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
const MisionVision = () => {
  return (
    <Box sx={{height: "230px" }}>
      <Grid
        sx={{ padding: 4 }}
        container
        spacing={2}
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid sx={{ minWidth: 275, minHeight: 300 }} item xs={12} sm={6} md={6}>
          <Card sx={{ border: "none" }}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                component="div"
                className="title-service-select"
                gutterBottom
              >
                NUESTRA VISIÓN
              </Typography>
              <Typography variant="body1" className="Texto_vision_mision">
              Para el 2028, ser reconocidos como el mejor
              operador logístico en cumplimiento de los
              estándares, índices de satisfacción del cliente
              y eficiencia en el desarrollo de los procesos.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid sx={{ minWidth: 275, minHeight: 300 }} item xs={12} sm={6} md={6}>
          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                component="div"
                className="title-service-select"
                gutterBottom
              >
                NUESTRA MISIÓN
              </Typography>
              <Typography variant="body1" className="Texto_vision_mision">
                Generar soluciones logísticas que cumplan con los requisitos de
                nuestros clientes en el menor tiempo posible y facilitando el
                seguimiento de todo el proceso, empleando altos estándares de
                tecnología y comunicación.{" "}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
    </Box>
  );
};

export default MisionVision;
