import React from "react";
import { useEffect } from 'react';
import { Box, Button, Grid, List, ListItem, Typography } from "@mui/material";
import Mensajeria from "../../Assets/Delivery_Images/mensajeria.jpg";
import Paquetes from "../../Assets/Delivery_Images/paquetes.jpg";
import "./style.css";
import { Link } from "react-router-dom";

const DeliveryExpressPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <Grid
      container
      item
      className="container-info"
    >
      <Grid xs={12} sm={6} md={6}>
      <div className="container_general_mensajeria">
        <div className="Titulo_1_mensajeria">
        <Typography variant="h4" sx={{ color: "#4548A3", fontWeight: "bold", fontSize: 24, textAlign: "left"}}>
          MENSAJERÍA EXPRESA
        </Typography><br></br>
        </div>
        <Typography variant="body1" className="container_texto_mensajeria" sx={{ paddingY: 2, lineHeight: 1.5 }}>
          Contamos con mensajería certificada y especializada con cobertura a
          más de 1000 municipios a nivel nacional. Además, en las principales
          ciudades del país, contamos con una solución biométrica móvil, robusta
          y sólida, que combina identificación por huella digital, cédula de
          ciudadanía, e integración de documentos soporte. Esta cuenta con:
        </Typography>
      </div>
      <div className="container_lista_mensajeria">
        <List>
          <ListItem>
            Almacenamiento, alistamiento y control de inventarios.
          </ListItem>
          <ListItem>
            Administración integral de sus procesos logísticos.
          </ListItem>
          <ListItem>
            Diseño de su operación logística de acuerdo a sus necesidades.
          </ListItem>
        </List>
        </div>
        <div className="boton">
        <Link to="/ubicacion">
        <Button className="button-general">
          Contáctanos
        </Button>
        </Link>
        </div>
      </Grid>
      <Grid xs={12} sm={5} md={5}>
        <Box sx={{ display: "flex", flexDirection: "column", position:'relative' }}>
          <img className="images-paqueteo" src={Mensajeria} alt="delivery" />
          <img
            className="images-paqueteo image2-paqueteo"
            src={Paquetes}
            alt="package delivery"
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default DeliveryExpressPage;
