import React, { useEffect } from "react";
import "./Tarifas.css"
import DownloadIcon from '@mui/icons-material/Download';
import { Typography, CircularProgress } from "@mui/material";
import Button from '@mui/material/Button';
import LazyLoad from 'react-lazy-load';
import IconButton from "@mui/material";


const Tarifas = () => {

const handleDownload = () => {
    const link = document.createElement('a');
    link.href = "https://www.dropbox.com/scl/fi/qu4mjm2rk58c6erun7x4c/Tarifas-BSL-2024.pdf?rlkey=zickeqtfm0krfy28j7c6e1zz2&dl=1"; // Ruta al archivo PDF local
    link.download = 'Tarifas BSL 2023.pdf'; // Nombre que se le asignará al archivo descargado
    link.click();
};

useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  return (
    <LazyLoad placeholder={<CircularProgress/>}>
    <div className="container_tarifas" loading="lazy">
        <div className='container_titulo'>
        <Button variant="contained" size="large" startIcon={<DownloadIcon className="icono_descarga"/>} onClick={handleDownload} className="boton_icono_down" >
        DESCARGA NUESTRAS TARIFAS
        </Button> 
        </div>
    </div>
    </LazyLoad>
  )
}

export default Tarifas