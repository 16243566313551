import React from 'react';

class FormularioHubSpotCotizaciones extends React.Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.charset = 'utf-8';
    script.type = 'text/javascript';
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '23479224',
        formId: 'cd47ca6b-86cf-4b25-bbae-66c47c513f35',
        target: '#formulario-hubspot'
      });
    };
  }

  componentWillUnmount() {
    const script = document.querySelector('script[src="//js.hsforms.net/forms/embed/v2.js"]');
    document.body.removeChild(script);
  }

  render() {
    return (
      <div id="formulario-hubspot">
        {/* El formulario de HubSpot se mostrará aquí */}
      </div>
    );
  }
}

export default FormularioHubSpotCotizaciones;