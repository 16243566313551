import React from "react";
import './style.css'
const Map = () => {
  return (
    <div className="container-map">
      <iframe
        title="This is a ubiication"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5624.05723172572!2d-74.09983985976018!3d4.628138479206091!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9bdf6eaaca77%3A0xd61a7ff00bf4120d!2sCra.%2042%20%2320c-24%20piso%203%2C%20Bogot%C3%A1!5e0!3m2!1ses-419!2sco!4v1683035084806!5m2!1ses-419!2sco"
        width="550"
        height="320"
        className="map"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Map;
